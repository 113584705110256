.PaginationContainer {
	display: flex;
	padding: 25px 0 ;
	height: 30px;
	align-items: center;
	justify-content: center;
	gap: 5px;

	.Badge {
		cursor: pointer;
	}
}
