.TodoDetails {
    display: flex;
    gap: 10px;
    padding: 10px 25px;

    .TableHeader {
        background-color: #F3F3F3;
        .HeaderCell {
            border: 1px solid #E9EAEC;
        }
    }

    .TodoDetailsCard {
        padding: 15px;
        border-radius: 5px;
        flex-basis: 100%;
    }

    .TodoDetailsCard2 {
        padding: 15px;
        border-radius: 5px;
        flex-basis: 50%;
        min-width: 350px;
    }

    .TodoDetailsHeader {
        height: 35px;
        display: flex;
        align-items: center;
    }

    .TodoDetailsBody {
        height: 750px;
        overflow-y: scroll;
        .SearchInput {
            width: 100%;
            margin: 10px 0
        }
        .OutputItemContainer {
            button {
                padding: 0;
            }
        }
    }

    .Records {
        display: flex;
        flex-direction: column;
        gap: 5px;
        border-radius: 5px;
    }

    .OutputInfo {
        display: flex;
        align-items: center;
    }
}

.TodoDetailsButtons {
    display: flex;
    gap: 5px;
    margin: 15px 0;
}

.StatusStyle {
    border: 1px solid red;
    padding: 5px;
    border-radius: 5px;
    background-color: rgba(255,0,0,0.2);
    color: red;
}

.FieldContainer {
    padding: 10px 30px;
    .FieldLabel {
    }
    .FieldValue {
        font-weight: bold;

    }
}
.OutputHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .OutputHeaderTitle {
        font-size: 15px;
        font-weight: bold;
    }
    .ExpandCollapseText {
        cursor: pointer;
        &:hover {
            color: #0088cc
        }
    }
}