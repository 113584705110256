.AuthPage {
    width: 400px;
    border: 1px solid rgba(0,0,0,0.4);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 25px;
    margin: 40px auto;
    gap: 10px;
    .LoginButton {
        justify-content: center;
        width: 40px;
        margin: auto;
    }
    h3 {
        margin: auto
    }
    .InvalidToken {
        color: red;
        font-size: 12px;
    }
}