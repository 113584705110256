.TodoRecords {}
.TodoRecordContainer {
    width: 100%;
    overflow-x: auto;
}
.ButtonActions {
    display: flex;
    justify-content: right;
    gap: 5px;
}

.LinkStyle {
    color: #0088cc;

}
.TableHeader {
    background-color: #F3F3F3;
    .HeaderCell {
        border: 1px solid #E9EAEC;
    }
}
.TableActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 10px;
}
.TableCellLayout{
    width: 100%;
    display: block !important;
    .DynamicInputData {
        width: 100%;
        .ListStyleSmall {
            text-align: left;
            padding-left: 17px;
            height: 60px;
            overflow: hidden;
        }
        .ListStyleBig {
            text-align: left;
            padding-left: 17px;
            height: 100%;
        }
        .MoreLess {
            text-align: right;
            cursor: pointer;
            &:hover {
                color: #0088cc;
            }
        }
    }
}

