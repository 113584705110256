.RecordsDialog {}
.FormFields {
    max-height: 400px;
    overflow-y: scroll;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px
}

.ButtonsContainer {
    display: flex;
    gap: 5px;
    margin-top: 20px
}

 /* Hide scrollbar by default */
 ::-webkit-scrollbar {
    width: 0.5em;
    background-color: white;
  }

  /* Make scrollbar visible when needed */
  ::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }

  /* Make scrollbar track visible when needed */
  ::-webkit-scrollbar-track {
    background-color: white;
  }