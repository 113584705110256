.TodoTabFilter {
    padding: 25px;
    .FormFilterLayout {
        border: 1px solid rgba(0,0,0,.3);
        border-radius: 5px;
        padding: 10px;
        .FieldsContainer {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 12px;
        }
        .LastFromRow {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 12px;

        }
    }

    .ButtonsContainer {
        width: 100%;
        display: flex;
        justify-content: right;
        padding-top: 15px;
    }

}
.Selections {
    display: flex;
}
.FieldsContainer > :nth-last-child(-n+3) {
    grid-column: span 1;
}